import React, { useEffect, useState } from 'react';
import * as styles from './landing.module.scss';
import Header from '@components/molecules/Header';
import { setLoginPopupOpen } from '@src/state/loginPopup/actions';
import Intro from '@components/molecules/videoSections/Intro';
import Presentation from '@components/molecules/videoSections/Presentation';
import Features from '@components/molecules/videoSections/Features';
import Reviews from '@components/molecules/landingSections/Reviews';
import VideoReviews from '@components/molecules/landingSections/VideoReviews';
import FAQ from '@components/molecules/landingSections/FAQ';
import Join from '@components/molecules/landingSections/Join';
import Materials from '@components/molecules/landingSections/Materials';
import CTA from '@components/molecules/videoSections/CTA';
import SmallDescription from '@components/molecules/landingSections/SmallDescription';
import SearchPlayer from '@components/molecules/SearchPlayer';
import LoginPopup from '@components/molecules/LoginPopup';
import Footer from '@components/molecules/Footer';
import FooterAdress from '@components/molecules/FooterAdress';
import { authService } from '../../services/authService';
import { setLoggedIn, setUserData } from '../../state/auth/actions';
import { connect, useSelector } from 'react-redux';
import { isBrowser } from '../../utils/ssr';
import { useIntl } from 'gatsby-plugin-react-intl';
import { sendEvent } from '@src/utils/amplitude';
import { mixPanelService } from '../../services/mixPanelService';
import useResponsive from '@src/utils/useResponsive';

const LandingLayoutNew = ({ setIsLoggedIn, setUserData, isLoginPopupOpen, setLoginPopupOpen }) => {
	const intl = useIntl();
	const account = useSelector((state) => state.authReducer);
	const [isDesktop, isTablet, isMobile] = useResponsive();

	const [playerSearchVisible, setPlayerSearchVisible] = useState(false);
	const [chaseButtonVisible, setChaseButtonVisible] = useState(false);

	useEffect(() => {
		checkingAuthorization();
		init();

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const checkingAuthorization = async () => {
		const profile = await authService.getUserData();

		if (profile.isLoggedIn && isBrowser) {
			window.location.href = `${window.location.protocol}//app.${window.location.hostname}`;
		}
	};

	const init = async () => {
		const profile = await authService.getUserData();

		if (profile.isLoggedIn) {
			setIsLoggedIn(true);
			setUserData(profile.accountData);
		}
	};

	const handleSearchPlayerClick = () => {
		if (playerSearchVisible) return;

		setPlayerSearchVisible(true);
	};

	const handleSearhPlayerExit = () => {
		if (!playerSearchVisible) return;

		setPlayerSearchVisible(false);
	};

	const handleScroll = () => {
		const scrollPosition = window.scrollY;
		const offsetIndex = !isMobile ? window.innerHeight * 0.8 : window.innerHeight * 0.8;

		if (scrollPosition > offsetIndex) {
			setChaseButtonVisible(true);
		} else {
			setChaseButtonVisible(false);
		}
	};

	// useEffect(() => {
	// 	if (isLoginPopupOpen) {
	// 		document.body.style.overflowY = 'hidden';
	// 	} else {
	// 		document.body.style.overflowY = 'scroll';
	// 	}
	// }, [isLoginPopupOpen]);

	useEffect(() => {
		if (isBrowser) {
			window.addEventListener('scroll', handleScroll);

			return () => {
				window.removeEventListener('scroll', handleScroll);
			};
		}
	}, [isMobile]);

	return (
		<div className={styles.landingLayout}>
			<Header onSearchPlayer={handleSearchPlayerClick} />

			{playerSearchVisible ? (
				<SearchPlayer onClose={handleSearhPlayerExit} />
			) : (
				<>
					<Intro />
					{/*<SmallDescription />*/}
					<div style={{ position: 'relative' }}>
						<Features />
						<VideoReviews />
						<Reviews />
						<Join />
						<Materials />
						<FAQ />
						<div className={[styles.stickyButton, chaseButtonVisible ? styles.stickyButtonVisible : ' '].join(' ')}>
							<button
								className={[styles.tryBtn].join(' ')}
								onClick={() => {
									setLoginPopupOpen(true);
									sendEvent('New Landing / Click Try button', {
										Location: 'slider',
									});
									mixPanelService.sendEvent(
										{
											event: 'Landing / Click Try Button',
											properties: {
												Location: 'slider',
											},
										},
										account?.userData?.steamAccountId || null
									);
								}}
							>
								{intl.formatMessage({ id: 'videolanding.intro.button' })}
							</button>
						</div>
					</div>
					<Footer />
				</>
			)}
			<LoginPopup isOpen={isLoginPopupOpen} onClose={() => setLoginPopupOpen(false)} />
		</div>
	);
};

const mapStateToProps = (state) => ({
	isLoginPopupOpen: state.loginPopupReducer.isOpen,
});

const mapDispatchToProps = (dispatch) => ({
	setIsLoggedIn: (payload) => dispatch(setLoggedIn(payload)),
	setUserData: (payload) => dispatch(setUserData(payload)),
	setLoginPopupOpen: (payload) => dispatch(setLoginPopupOpen(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingLayoutNew);
